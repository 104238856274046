/**
 * 员工销售业绩api
 */
 import * as API from '@/api/index'

 export default {
     // 营业报表-实收统计
     getEmployeeSalePerformanceCommissionDetail: params => {
         return API.POST('api/employeeSlimmingStatement/list', params)
     },
     // 销售业绩提成明细列表
     getEmployeeSalePerformanceCommissionList:params => {
       return API.POST('api/employeeSalePerformanceCommissionDetailStatement/list',params)
     },
    // 54.9.员工销售业绩提成明细导出
    exportEmployeeSalePerformanceCommissionDetailStatement: params => {
      return API.exportExcel('api/employeeSalePerformanceCommissionDetailStatement/excel', params)
    },

    // 通讯录-员工职位
    getJobJobtypeAll: params => {
      return API.POST('api/jobtype/all', params)
    },

    // 导出
    getdownloadExcel:params => {
      return API.exportExcel('api/employeeSlimmingStatement/excel',params)
    }

 }